import { createSignal, onMount } from 'solid-js'
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils'
import { createEffect } from "solid-js";

import Input from './Input'

import './flags.css'

function PhoneInput(props) {
  const [phoneInputValue, setPhoneInputValue] = createSignal({ [props.name]: '' })
  let telInput = null

  onMount(() => {
    telInput = intlTelInput(
      document.querySelector(`#${props.name}`),
      {
        initialCountry: props.initialCountry ?? 'hn',
        separateDialCode: true,
        strictMode: true,
        allowDropdown: false,
        onlyCountries: props.onlyCountries,
        formatOnDisplay: false,
        nationalMode: false,
        validationNumberType: 'FIXED_LINE_OR_MOBILE'
      }
    )
  })

  createEffect(() => {
    if(props.values().country) {
      telInput.setCountry(props.values().country)
    }
  })

  const handlePhoneInputChange = (event) => {
    const { value } = event.target
    const phoneNumber = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '')
    setPhoneInputValue({
      [props.name]: phoneNumber,
    })
    const countryCode = telInput.getSelectedCountryData()?.dialCode
    props.onChange({ phoneNumber, countryCode })
  }

  return (
    <Input
      {...props}
      values={phoneInputValue}
      onChange={handlePhoneInputChange}
      type="tel"
    /> 
  )
}

export default PhoneInput

